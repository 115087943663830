import React from "react";
import n1 from "../images/1.svg";
import n2 from "../images/2.svg";
import n3 from "../images/3.svg";
import s1 from "../images/home/step1.png";
import s2 from "../images/home/step2.png";
import s3 from "../images/home/step3.png";
import mobileVirtual from "../images/mobile/virtual.svg";
import mobileBlobL from "../images/mobile/blob-left.svg";
import mobileBlobR from "../images/mobile/blob-right.svg";
import mobileMeal from "../images/mobile/meal.png";
import bubble from "../images/home/bubble.svg";
import SignUpNew from "./SignUpNew.react";
import "../styles/HomePage.scss";
import { analytics } from "firebase";
import { Link } from "react-router-dom";

export default function HomePage() {
  return (
    <div className="home">
      <div className="splash">
        <div className="shared_container splash_container">
          <h1 data-aos="fade-up">
            Adding Flavor
            <br /> to Social Distancing
          </h1>
          <p data-aos="fade-up" data-aos-delay="200">
            Creating companionship through intergenerational dinner parties over
            video calls.
          </p>
          <a
            href="#signUp"
            onClick={() => {
              analytics().logEvent("primary-cta-click");
            }}
            className="cool marginBottom12 marginRight8"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            {window.innerWidth < 800
              ? `Sign Up`
              : `Reserve a seat at the table`}
          </a>
          {window.innerWidth < 800 && (
            <Link to="/login" className="cool outline">
              Login
            </Link>
          )}
        </div>
      </div>
      <div className="SplashMission ContainerWhite">
        <div className="shared_container SplashMission_container">
          <img
            className="mobileOnly"
            src={mobileVirtual}
            alt="connecting hands through a computer screen"
            data-aos="fade-up"
          />
          <h4 className="marginBottom20" data-aos="fade-up">
            At MealsTogether, we’re creating companionship when loneliness is at
            an all time high for older adults and youth alike.
          </h4>
          <p className="marginLeftAuto" data-aos="fade-up">
            To do this, we pair you with a FoodFriend from a different
            generation to share a meal over a video call and cook up hearty
            conversations!
          </p>
        </div>
      </div>
      <div className="SplashSharedMeals ContainerPale ">
        <div className="shared_container SplashSharedMeals_container">
          <div className="SplashSharedMealsContent">
            <h2 data-aos="fade-up">
              Meals are <br /> meant to be shared
            </h2>
            <p className="marginBottom20" data-aos="fade-up">
              For centuries, young and old have come together to share meals and
              stories. Meal time is our time to nurture and connect, to laugh
              and learn, and to be seen and heard.
            </p>
            <p className="marginBottom20" data-aos="fade-up">
              But our shifting society has made it harder for different
              generations to connect, and the resulting loneliness takes a big
              toll on our health. Social distancing from the COVID-19 pandemic
              has made this even harder.
            </p>
            <p data-aos="fade-up">
              Luckily, there’s a way around it: video calls. With video
              available on many smartphones and computers, you can connect and
              share more simply than ever before.
            </p>
          </div>
        </div>
      </div>

      <div className="ForMe ContainerWhite">
        <img
          src={mobileMeal}
          alt="Meal with love plate"
          className="mobileMeal mobileOnly"
        ></img>
        <div className="shared_container ForMe_container">
          <h2 data-aos="fade-up">Is this for me?</h2>
          <p className="marginBottom20" data-aos="fade-up">
            This is for people who want to find community with just about anyone
            over a good meal. Whether young or old, there’s a seat for you at
            our table.
          </p>
          <p data-aos="fade-up">
            If you want to help someone stay connected to the world, yearn for
            quality human connections, or are just curious about people,
            MealsTogether is for you.
          </p>
        </div>
      </div>

      <div className="Need ContainerPrimary">
        <div className="shared_container Need_container">
          <div className="NeedContent">
            <h2 data-aos="fade-up">What do I need?</h2>
            <p data-aos="fade-up">
              A <b>phone</b> or <b>computer</b> with video call capabilities, or
              a webcam, or even just a call via dial-in on a telephone.
            </p>
          </div>
        </div>
      </div>

      <div className="howItWorks ContainerWhite ContainerCenter">
        <div className="shared_container howItWorks_container">
          <h2 data-aos="fade-up">How It Works</h2>
          <div className="howItWorks_steps">
            {[
              {
                num: n1,
                image: s1,
                heading: "1. Sign Up",
                text:
                  "Share a bit about yourself and let us know when you're having your meals."
              },
              {
                num: n2,
                image: s2,
                heading: "2. Get matched",
                text:
                  "We'll match you with someone from another generation based on your availability, language preference, and interests."
              },
              {
                num: n3,
                image: s3,
                heading: "3. Share a Meal",
                text:
                  "Get your dinner ready and connect with your FoodFriend over a video call or dial-in virtually. "
              }
            ].map((key, index) => (
              <div key={index} className="howItWorks_step" data-aos="fade-up">
                <div className="howItWorks_stepText">
                  <div className="howItWorks_stepTextNum">
                    <img src={key.image} alt={`Step - ${index}`}></img>
                  </div>
                  <div className="howItWorks_stepTextCopy">
                    {/* <div className="howItWorks_stepImage">
                      <img src={key.image} alt={key.text}></img>
                    </div> */}
                    <h3>{key.heading}</h3>
                    <p>{key.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="Testimonials ContainerDark">
        <div className="shared_container Testimonials_container">
          <div className="TestimonialsContent">
            <img src={bubble} alt="speech bubble" />
            <h2 data-aos="fade-up">Testimonials</h2>
            <p data-aos="fade-up">
              &ldquo;Shyam is a great person. 10 of 10 scale for ease,
              connectivity, and conversation. We actually had a great
              conversation and we have a lot of common interests.&rdquo;
            </p>
            <p data-aos="fade-up">
              <b>N. Bostarr</b>
            </p>
          </div>
        </div>
        <div name="signUp" id="signUp"></div>
      </div>

      <div className="signUp">
        <div className="shared_container signUp_container">
          <SignUpNew />
        </div>
      </div>
    </div>
  );
}
