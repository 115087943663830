const interestOptionsMapLegacy = {
  africanHistory: "African History",
  airSports: "Air Sports",
  airsoft: "Airsoft",
  amateurradio: "Amateur radio",
  americanFootball: "American Football",
  americanHistory: "American History",
  animalCare: "Animal Care",
  aquariumkeeping: "Aquarium keeping",
  archery: "Archery",
  asianHistory: "Asian History",
  astronomy: "Astronomy",
  audiophilia: "Audiophilia",
  autoracing: "Auto racing",
  backpacking: "Backpacking",
  badminton: "Badminton",
  baking: "Baking",
  baseball: "Baseball",
  basketball: "Basketball",
  batonTwirling: "Baton Twirling",
  beach: "Beach",
  bicycling: "Bicycling",
  billiards: "Billiards",
  birdWatching: "Bird Watching",
  boardSports: "Board Sports",
  boating: "Boating",
  bonsai: "Bonsai",
  books: "Books",
  bowling: "Bowling",
  bundesliga: "Bundesliga",
  camping: "Camping",
  churchActivities: "Church Activities",
  climbing: "Climbing",
  coffee: "Coffee",
  collegeFootball: "College Football",
  computer: "Computer",
  computerProgramming: "Computer Programming",
  cooking: "Cooking",
  crafts: "Crafts",
  creativeWriting: "Creative Writing",
  cricket: "Cricket",
  cycling: "Cycling",
  dance: "Dance",
  dancing: "Dancing",
  datingOnline: "Dating Online",
  discGolf: "Disc Golf",
  drawing: "Drawing",
  eatingOut: "Eating Out",
  entertaining: "Entertaining",
  entrepreneurship: "Entrepreneurship",
  equestrianism: "Equestrianism",
  europeanHistory: "European History",
  exercise: "Exercise",
  familyTime: "Family Time",
  figureskating: "Figure skating",
  fishing: "Fishing",
  fitness: "Fitness",
  footbag: "Footbag",
  football: "Football",
  freerunning: "Freerunning",
  gardening: "Gardening",
  genealogy: "Genealogy",
  geocaching: "Geocaching",
  geology: "Geology",
  golf: "Golf",
  golfing: "Golfing",
  gymnastics: "Gymnastics",
  hiking: "Hiking",
  homeAutomation: "Home Automation",
  homeMovies: "Home Movies",
  horsebackRiding: "Horseback Riding",
  housework: "Housework",
  hunting: "Hunting",
  ipl: "IPL",
  iceHockey: "Ice Hockey",
  jewelryMaking: "Jewelry Making",
  jogging: "Jogging",
  kartRacing: "Kart Racing",
  kayaking: "Kayaking",
  knapping: "Knapping",
  knitting: "Knitting",
  laLiga: "La Liga",
  lapidary: "Lapidary",
  latinAmericanHistory: "Latin American History",
  legal: "Legal",
  listeningToMusic: "Listening To Music",
  locksport: "Locksport",
  mlb: "MLB",
  mls: "MLS",
  machining: "Machining",
  medicine: "Medicine",
  meteorology: "Meteorology",
  motorSports: "Motor Sports",
  motorcycling: "Motorcycling",
  mountainBiking: "Mountain Biking",
  movies: "Movies",
  musicalInstruments: "Musical Instruments",
  mysteryNovels: "Mystery Novels",
  nba: "NBA",
  nfl: "NFL",
  nhl: "NHL",
  netball: "Netball",
  paintball: "Paintball",
  painting: "Painting",
  parkour: "Parkour",
  pets: "Pets",
  photography: "Photography",
  planking: "Planking",
  playingCards: "Playing Cards",
  playingMusic: "Playing Music",
  politics: "Politics",
  premierLeague: "Premier League",
  racquetball: "Racquetball",
  reading: "Reading",
  realityTV: "Reality TV",
  relaxing: "Relaxing",
  rentingMovies: "Renting Movies",
  rockClimbing: "Rock Climbing",
  rowing: "Rowing",
  rugby: "Rugby",
  running: "Running",
  sailing: "Sailing",
  sandCastleBuilding: "Sand Castle Building",
  scrapbooking: "Scrapbooking",
  sculpting: "Sculpting",
  sewing: "Sewing",
  shooting: "Shooting",
  shopping: "Shopping",
  singing: "Singing",
  skiing: "Skiing",
  sleeping: "Sleeping",
  smoking: "Smoking",
  socializing: "Socializing",
  squash: "Squash",
  stampCollecting: "Stamp Collecting",
  studying: "Studying",
  surfing: "Surfing",
  swimming: "Swimming",
  tableTennis: "Table Tennis",
  taiChi: "Tai Chi",
  teamSports: "Team Sports",
  tennis: "Tennis",
  theater: "Theater",
  travel: "Travel",
  traveling: "Traveling",
  unions: "Unions",
  vehicleRestoration: "Vehicle Restoration",
  volleyball: "Volleyball",
  volunteerWork: "Volunteer Work",
  walking: "Walking",
  watchingSports: "Watching Sports",
  watchingTV: "Watching TV",
  waterSports: "Water Sports",
  woodworking: "Woodworking",
  workingOnCars: "Working On Cars",
  writing: "Writing",
  yoga: "Yoga"
};

const interestOptionsMap = {
  reading: "Reading or Writing",
  food: "Food or Cooking",
  travel: "Traveling or the Outdoors",
  health: "Health and Wellness",
  school: "School, Work, Career, or Service",
  history: "History or Policy",
  sports: "Sports",
  arts: "Arts and Crafts",
  music: "Music, Television, or Movies.",
  other: "Other"
};

const interestOptions = Object.keys(interestOptionsMap).map(key => ({
  value: key,
  label: interestOptionsMap[key]
}));

const ageOptionsMap = {
  genz: "18-22",
  millennial: "23-38",
  genx: "39-54",
  boomers: "55-73",
  silent: "74-91",
  greatest: "92+"
};

const multiSelectTheme = {
  primary: "#4285F4",
  hover: "#f1f3f5",
  border: "#ccc",
  gray: "#aaa",
  background: "#fff",
  borderRadius: "4px",
  height: "38px",
  color: "#000"
};

const countries = [
  { value: "AF", label: "Afghanistan" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia and Herzegowina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo" },
  { value: "CD", label: "Congo, the Democratic Republic of the" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Cote d'Ivoire" },
  { value: "HR", label: "Croatia (Hrvatska)" },
  { value: "CU", label: "Cuba" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "TP", label: "East Timor" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands (Malvinas)" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "FX", label: "France, Metropolitan" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard and Mc Donald Islands" },
  { value: "VA", label: "Holy See (Vatican City State)" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran (Islamic Republic of)" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KP", label: "Korea, Democratic People's Republic of" },
  { value: "KR", label: "Korea, Republic of" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Lao People's Democratic Republic" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libyan Arab Jamahiriya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macau" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia, Federated States of" },
  { value: "MD", label: "Moldova, Republic of" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "AN", label: "Netherlands Antilles" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "Reunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russian Federation" },
  { value: "RW", label: "Rwanda" },
  { value: "KN", label: "Saint Kitts and Nevis" },
  { value: "LC", label: "Saint LUCIA" },
  { value: "VC", label: "Saint Vincent and the Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome and Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SK", label: "Slovakia (Slovak Republic)" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SH", label: "St. Helena" },
  { value: "PM", label: "St. Pierre and Miquelon" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard and Jan Mayen Islands" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syrian Arab Republic" },
  { value: "TW", label: "Taiwan, Province of China" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania, United Republic of" },
  { value: "TH", label: "Thailand" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks and Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "UM", label: "United States Minor Outlying Islands" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Viet Nam" },
  { value: "VG", label: "Virgin Islands (British)" },
  { value: "VI", label: "Virgin Islands (U.S.)" },
  { value: "WF", label: "Wallis and Futuna Islands" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "YU", label: "Yugoslavia" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" }
];

const languageOptionsMap = {
  english: "English",
  arabic: "Arabic",
  bengali: "Bengali",
  cantonese: "Cantonese",
  farsi: "Farsi",
  french: "French",
  german: "German",
  hausa: "Hausa",
  hindi: "Hindi",
  italian: "Italian",
  japanese: "Japanese",
  korean: "Korean",
  malay: "Malay",
  mandarin: "Mandarin",
  marathi: "Marathi",
  portuguese: "Portuguese",
  punjabi: "Punjabi",
  russian: "Russian",
  spanish: "Spanish",
  swahili: "Swahili",
  tamil: "Tamil",
  telugu: "Telugu",
  thai: "Thai",
  urdu: "Urdu",
  vietnamese: "Vietnamese"
};
const languageOptions = Object.keys(languageOptionsMap).map(key => ({
  value: key,
  label: languageOptionsMap[key]
}));

const times = {
  // 8: false,
  9: false,
  10: false,
  11: false,
  12: false,
  13: false,
  14: false,
  15: false,
  16: false,
  17: false,
  18: false,
  19: false,
  20: false
  // 21: false,
  // 22: false
};

const pickerOptions = {
  monday: {
    ...times
  },
  tuesday: {
    ...times
  },
  wednesday: {
    ...times
  },
  thursday: {
    ...times
  },
  friday: {
    ...times
  },
  saturday: {
    ...times
  },
  sunday: {
    ...times
  }
};

const milisecondToMinutes = 60000;

const constants = {
  ageOptionsMap,
  interestOptionsMap,
  interestOptionsMapLegacy,
  interestOptions,
  multiSelectTheme,
  countries,
  languageOptionsMap,
  languageOptions,
  times,
  pickerOptions,
  milisecondToMinutes
};

export default constants;
