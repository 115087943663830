import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { firestore, auth } from "firebase";
import MultiSelect from "react-multi-select-component";
import constants from "./constants";
import TimePicker from "./TimePicker.react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useAuthState } from "react-firebase-hooks/auth";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Alert, AlertTitle } from "@material-ui/lab";
import { Link } from "react-router-dom";

export default function SignUp() {
  const { interestOptions, interestOptionsMap, multiSelectTheme } = constants;
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    setValue
  } = useForm();
  const [user] = useAuthState(auth());
  const [interests, setInterests] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [communication, setCommunication] = useState("");
  const [pickerState, setPickerState] = useState({
    ...constants.pickerOptions
  });
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (
      status != null ||
      (userInfo == null && user != null && user.uid != null)
    ) {
      firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();
            setUserInfo(doc.data());
            setValue([
              { first_name: data.first_name },
              { last_name: data.last_name }
            ]);
            setCommunication(data.communication_preference);
            setInterests(
              data.interests.map(interest => ({
                value: interest,
                label: interestOptionsMap[interest]
              }))
            );

            setLanguages(
              data.languages.map(language => ({
                value: language,
                label: constants.languageOptionsMap[language]
              }))
            );

            setPickerState(data.times);
          }
        });
    }
  }, [status, user, setValue, interestOptionsMap]);

  // TODO: Add calendar time and better error handling
  const onSubmit = data => {
    const { first_name, last_name } = data;

    // TODO: Handle error gracefully
    if (first_name == null || languages.length === 0) {
      return;
    }

    const structuredData = {
      //   approved: true,
      first_name,
      last_name,
      interests: interests.map(interest => interest.value),
      languages: languages.map(language => language.value),
      times: {
        ...pickerState
      },
      communication_preference: communication
    };

    console.log("Details: ", structuredData);

    firestore()
      .collection("users")
      .doc(user.uid)
      .update(structuredData)
      .then(
        val => {
          console.log(val);
          setStatus(`The changes have been saved successfully`);
        },
        err => console.log(err)
      );
  };

  if (userInfo?.active === false) {
    return (
      <div className="EditProfile">
        <div className="shared_container">
          <h3>Account Deactivated</h3>
        </div>
      </div>
    );
  }
  return (
    <div className="EditProfile">
      <div className="shared_container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>Edit Profile</h2>
          <p className="marginBottom20">
            Here, you can edit information about your profile and disable your
            account
          </p>
          <div className="FormFlex">
            <div className="form__div__new">
              {/* <label htmlFor="first_name">Hi! I'm:</label> */}
              <InputLabel id="label-fname" className="MuiInputLabel-shrink">
                First Name / Preferred Name
              </InputLabel>
              <TextField
                error={errors?.first_name?.message != null}
                inputRef={register({ required: true })}
                type="text"
                id="first_name"
                name="first_name"
                required
                onChange={e => {
                  if (e.target.value === "") {
                    setError("first_name", "incorrect", "Name cannot be empty");
                  } else {
                    clearError("first_name");
                  }
                }}
                onBlur={e => {
                  if (e.target.value === "") {
                    setError("first_name", "incorrect", "Name cannot be empty");
                  } else {
                    clearError("first_name");
                  }
                }}
              />
              <FormHelperText>
                {errors?.first_name?.message != null &&
                  errors?.first_name?.message}
              </FormHelperText>
            </div>

            <div className="form__div__new">
              <InputLabel id="label-lname" className="MuiInputLabel-shrink">
                Last Name
              </InputLabel>
              <TextField
                inputRef={register}
                type="text"
                id="last_name"
                name="last_name"
              />
            </div>
          </div>

          <div className="form__div__new">
            <InputLabel
              id="label-communication"
              className="MuiInputLabel-shrink"
            >
              What's your preferred mode of communication?
            </InputLabel>

            <Select
              id="communication"
              name="communication"
              labelId="label-communication"
              required
              value={communication}
              onChange={event => {
                setCommunication(event.target.value);
              }}
            >
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
            <FormHelperText>
              After you sign up, this is where we will send you updates!
            </FormHelperText>
          </div>

          <h3>Meals</h3>

          <div className="form__div__new">
            <InputLabel id="label-interests" className="MuiInputLabel-shrink">
              Interests *
            </InputLabel>
            <MultiSelect
              value={interests}
              onChange={selectedOption => setInterests(selectedOption)}
              options={interestOptions}
              hasSelectAll={false}
              theme={multiSelectTheme}
            />
            <FormHelperText>
              Tell us more about what matters to you and how you spend your
              time!
            </FormHelperText>
          </div>

          <div className="form__div__new">
            <InputLabel id="label-interests" className="MuiInputLabel-shrink">
              Which languages do you speak? *
            </InputLabel>
            <MultiSelect
              value={languages}
              onChange={selectedOption => setLanguages(selectedOption)}
              options={constants.languageOptions}
              hasSelectAll={false}
              theme={multiSelectTheme}
            />
            <FormHelperText>
              Please only choose languages in which you are a fluent speaker.
            </FormHelperText>
          </div>

          <p className="marginBottom20 formHelper">
            Let us know when you're free to eat on a daily basis. To do this,
            click on one of the days <i>(for example Tu is for Tuesday)</i> and
            then highlight (it will turn light blue) the times you're typically
            available that day. The more times you select, the sooner you will
            be partnered! Note that your meal will start on the hour and last 40
            mins.
          </p>

          {/* <p className="marginBottom20 formHelper">
            <i>
              <b>
                Please note the below times are all in Pacific Standard Time
                (PST).
              </b>
            </i>
          </p> */}

          <div className="marginBottom20">
            <TimePicker
              pickerState={pickerState}
              setPickerState={setPickerState}
            />
          </div>

          <div className="form__div__new">
            <input type="submit" className="cool" />
          </div>
        </form>

        <button
          class=" cool outline marginBottom20"
          onClick={() => {
            //daactivating
            confirmAlert({
              title: "Are you sure you want to deactivate your account?",
              message: "You won't be matched with anyone else anymore",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    firestore()
                      .collection("users")
                      .doc(user.uid)
                      .update({
                        active: false
                      })
                      .then(() => {
                        window.location = "/";
                      });
                  }
                },
                {
                  label: "No",
                  onClick: () => {}
                }
              ]
            });
          }}
        >
          Deactivate Account
        </button>
        {status != null && (
          <Alert
            className="marginBottom20"
            severity="success"
            onClose={() => {
              setStatus(null);
            }}
          >
            <AlertTitle>Profile Updated</AlertTitle>
            {status} <Link to="/">Click here to go back to home page</Link>
          </Alert>
        )}
      </div>
    </div>
  );
}
