import React from "react";
import "../styles/TimePicker.scss";
import Chip from "@material-ui/core/Chip";
import { renderTimeMinimal } from "../Utils/timeUtils";

function SelectedTimes({ pickerState }) {
  return (
    <div className="TimePickerSelectedTimes">
      {Object.keys(pickerState).map(day => {
        const times = Object.keys(pickerState[day])
          .map(time => {
            if (pickerState[day][time]) {
              return (
                <Chip
                  className="TimePickerSelectedTimesChip"
                  key={`${day}-${time}`}
                  label={`${renderTimeMinimal(time)}`}
                />
              );
            } else {
              return null;
            }
          })
          .filter(Boolean);

        if (times.length) {
          return (
            <div className="TimePickerSelectedTimesDay">
              {day}
              {times}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default SelectedTimes;

// function renderTime(time) {
// const now = moment.tz(moment().format(), "America/Los_Angeles");
// return `${moment
//   .tz(
//     now
//       .clone()
//       .hour(time)
//       .format(),
//     "America/Los_Angeles"
//   )
//   .format("ha")} - ${moment
//   .tz(
//     now
//       .clone()
//       .hour(time)
//       .add("1", "hour")
//       .format(),
//     "America/Los_Angeles"
//   )
//   .format("ha")}`;
//   time = Number(time);
//   if (time < 12) {
//     return `${time}AM - ${time + 1}${time + 1 < 12 ? "AM" : "PM"}`;
//   } else if (time === 12) {
//     return "12PM - 1PM";
//   } else {
//     return `${time - 12}PM - ${time - 12 + 1}PM`;
//   }
// }
// function renderTimeInYourTimeZone(time) {
//   const now = moment.tz(moment().format(), "America/Los_Angeles");
//   const floorTime = moment
//     .tz(
//       now
//         .clone()
//         .hour(time)
//         .format(),
//       "America/New_York"
//     )
//     .format("ha");
//   const cielTime = moment
//     .tz(
//       now
//         .clone()
//         .hour(time)
//         .add("1", "hour")
//         .format(),
//       "America/New_York"
//     )
//     .format("ha");
//   return `${floorTime} - ${cielTime} ${
//     moment
//       .tz(
//         now
//           .clone()
//           .hour(time)
//           .add("1", "hour")
//           .format(),
//         "America/New_York"
//       )
//       .day() >
//     moment
//       .tz(
//         now
//           .clone()
//           .hour(time)
//           .add("1", "hour")
//           .format(),
//         "America/Los_Angeles"
//       )
//       .day()
//       ? "+1"
//       : ""
//   }`;
//   time = Number(time);
//   if (time < 12) {
//     return `${time}AM - ${time + 1}${time + 1 < 12 ? "AM" : "PM"}`;
//   } else if (time === 12) {
//     return "12PM - 1PM";
//   } else {
//     return `${time - 12}PM - ${time - 12 + 1}PM`;
//   }
// }
