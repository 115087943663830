import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// import { Menu, MenuItem } from "@material-ui/core";

const { firestore, auth, analytics } = require("firebase");
const logo = require("../images/logo.svg");

function CurrentUser() {
  const [user, initialising, error] = useAuthState(auth());
  const [userInfo, setUserInfo] = useState(null);
  // const [loginClicked, setLoginClicked] = useState(false);
  // const { register, handleSubmit } = useForm();
  const location = useLocation();
  const [navClass, setNavClass] = useState("");
  // const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (user != null && user.uid != null) {
      firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then(userDoc => {
          if (userDoc.exists) {
            setUserInfo(userDoc.data());
          }
        });
    }
  }, [user]);

  // useEffect(() => {
  //   document.addEventListener("scroll", () => {
  //     if (window.scrollY > 500) {
  //       setNavClass("App-header-white");
  //     } else {
  //       setNavClass("");
  //     }
  //   });
  // }, []);

  const logout = () => {
    auth().signOut();
    window.location = "/";
  };

  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // if (initialising) {
  //   return (
  //     <div>
  //       <p>Initialising User...</p>
  //     </div>
  //   );
  // }
  // if (error) {
  //   return (
  //     <div>
  //       <p>Error: {error}</p>
  //     </div>
  //   );
  // }

  return (
    <header
      className={`App-header ${navClass} ${
        location != null && location.pathname !== "/" ? "AppHeaderDark" : ""
      }`}
    >
      <div className="app_headerContainer">
        {/* <button onClick={onClickTrigger}>Trigger Match</button> */}

        <Link to="/" className="App-logo">
          <img src={logo} className="App-logo" alt="logo" />
        </Link>
        {user != null ? (
          <div className="NavButtons">
            <Link to="/about" className="cool dark">
              Team
            </Link>
            <Link to="/profile" className="undecorated">
              <button className="dark cool">Edit Profile</button>
            </Link>
            <button
              className="dark cool"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </button>
            {/* <button
              className="dark cool"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              Hi, {userInfo != null ? userInfo.first_name : null}
            </button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link to="/profile" className="undecorated">
                  Profile
                </Link>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  logout();
                }}
              >
                Logout
              </MenuItem>
            </Menu> */}
          </div>
        ) : (
          <div className="NavButtons">
            <Link to="/about" className="cool dark">
              Team
            </Link>
            <Link to="/login" className="cool dark">
              Log in
            </Link>
            {user == null && (
              <HashLink
                to="/#signUp"
                onClick={() => {
                  analytics().logEvent("nav-cta-click");
                }}
                className="cool "
              >
                Sign Up
              </HashLink>
            )}
          </div>
        )}
      </div>
    </header>
  );
}

export default CurrentUser;

// firestore()
//   .collection("meetings")
//   .add({
//     /*users: [
//                     {
//                       id: "sERM5ppeevSrKQqZbmSfXf3nPlx2",
//                       status: "waiting"
//                     },
//                     {
//                       id: "hxwTALrQhtNNVb7lAVmCnuY4d613",
//                       status: "waiting"
//                     }
//                   ],*/
//     user1: {
//       id: "wVdkLEQeeUZmrVtW1PmoT7o9Lfj2",
//       status: "waiting"
//     },
//     user2: {
//       id: "GkXiXhcuHETlaYYqxpblGR5utJH3",
//       status: "waiting"
//     },
//     scheduled: false,
//     meet_time: moment
//       .tz(
//         now
//           .clone()
//           .day("monday")
//           .hour(9)
//           .minute(0)
//           .second(0)
//           .add("1", "week")
//           .format(),
//         "America/Los_Angeles"
//       )
//       .format(),
//     zoom_meeting: "empty"
//   });
