import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import AOS from "aos";
import "aos/dist/aos.css";
const { initializeApp, analytics } = require("firebase");

// eslint-disable-next-line eqeqeq
if (
  process.env.NODE_ENV === "test" ||
  process.env.NODE_ENV === "development" ||
  (process.env.REACT_APP_IS_STAGING != null &&
    process.env.REACT_APP_IS_STAGING.toString() === "1")
) {
  initializeApp({
    apiKey: "AIzaSyDHZI_r1H_S9fZ8_5UUuSYqg0AnuYqE_uk",
    authDomain: "e-meal-staging.firebaseapp.com",
    databaseURL: "https://e-meal-staging.firebaseio.com",
    projectId: "e-meal-staging",
    storageBucket: "e-meal-staging.appspot.com",
    messagingSenderId: "794844797287",
    appId: "1:794844797287:web:ccbdf807164178312737c2",
    measurementId: "G-F2E06EKN8F"
  });
} else {
  initializeApp({
    apiKey: "AIzaSyCzWuQFhbwFz--Hv4FNvP6PK7aiLgXE4Vk",
    authDomain: "e-meal-161a9.firebaseapp.com",
    databaseURL: "https://e-meal-161a9.firebaseio.com",
    projectId: "e-meal-161a9",
    storageBucket: "e-meal-161a9.appspot.com",
    messagingSenderId: "329143748515",
    appId: "1:329143748515:web:d73cdf9b400b14b1987c16",
    measurementId: "G-WK558E0S05"
  });
}
analytics();
AOS.init({
  easing: "ease-in-out",
  duration: 400,
  mirror: false,
  once: true
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
