import React, { useEffect, useState } from "react";
import "./styles/App.scss";
import "./styles/shared.scss";
import "./styles/form.scss";
import CurrentUser from "./Components/CurrentUser.react";
import HomePage from "./Components/HomePage.react";
import LoggedInPage from "./Components/LoggedInPage.react";
import TeamPage from "./Components/TeamPage.react";
import { firestore, auth } from "firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Login from "./Components/Login.react";
import Forgot from "./Components/Forgot.react";
import Profile from "./Components/Profile.react";
const logo = require("./images/logo.svg");

function App() {
	const [user, initialising, error] = useAuthState(auth());
	const [userInfo, setUserInfo] = useState(null);

	useEffect(() => {
		if (user != null && user.uid != null) {
			firestore()
				.collection("users")
				.doc(user.uid)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setUserInfo(doc.data());
					}
				});
		}
	}, [user]);

	return (
		<Router>
			<div className="App">
				<CurrentUser />

				<div className="app__container">
					<Switch>
						<Route path="/profile">
							{/* {user != null && user.uid != null ? null : <Redirect to="/" />} */}
							<Profile />
						</Route>
						<Route path="/login">
							{user != null && user.uid != null ? <Redirect to="/" /> : null}
							<Login />
						</Route>
						<Route path="/forgot">
							{user != null && user.uid != null ? <Redirect to="/" /> : null}
							<Forgot />
						</Route>
						<Route path="/about">
							<TeamPage />
						</Route>
						<Route path="/">
							{user != null ? null : <HomePage />}
							{user != null && userInfo != null && (
								<LoggedInPage userInfo={userInfo} />
							)}
						</Route>
					</Switch>
				</div>

				<footer>
					<div className="ContainerDark Footer">
						<div className="shared_container">
							<img src={logo} alt="logo"></img>
							<p>Phone: +1-562-285-7060</p>
							<p>Email: mealstogether@theyouthmovement.org</p>
							<p>
								MealsTogether is an initiative of{" "}
								<a href="http://www.theyouthmovement.org">
									The Youth Movement Against Alzheimer's
								</a>
							</p>
						</div>
					</div>
				</footer>
			</div>
		</Router>
	);
}

export default App;
