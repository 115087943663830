import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { firestore, auth, analytics } from "firebase";
import MultiSelect from "react-multi-select-component";
import constants from "./constants";
import TimePicker from "./TimePicker.react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Alert, AlertTitle } from "@material-ui/lab";

export default function SignUp() {
  const { interestOptions, multiSelectTheme } = constants;
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setError,
    clearError
  } = useForm();
  const [pswdError, setPswdError] = useState(null);
  const [confirmPswd, setConfirmPswd] = useState("");
  const [interests, setInterests] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [age, setAge] = useState("");
  const [communication, setCommunication] = useState("");
  const [caregiver, setCaregiver] = useState("no");
  const [pickerState, setPickerState] = useState({
    ...constants.pickerOptions
  });
  const [customError, setCustomError] = useState(null);

  // TODO: Add calendar time and better error handling
  const onSubmit = (data) => {
    setCustomError(null);
    console.log(errors);
    let timeSet = false;

    debugger;
    Object.keys(pickerState).forEach((day) => {
      Object.keys(pickerState[day]).forEach((time) => {
        if (pickerState[day][time] === true) {
          timeSet = true;
          return;
        }
      });
      if (timeSet) {
        return;
      }
    });
    if (!timeSet) {
      setCustomError(
        "You need to select atleast one time you are available in order to register"
      );
      return;
    }

    if (data.password?.length < 7) {
      setCustomError("Password needs to be atleast 6 characters long");
      return;
    }
    if (data.password !== confirmPswd) {
      setCustomError("Passwords do not match");
      return;
    }
    if (data.password === confirmPswd) {
      const { first_name, last_name, email, phone, webcam } = data;
      const { zipcode } = data;

      // TODO: Handle error gracefully
      if (
        phone == null ||
        first_name == null ||
        email == null ||
        languages.length === 0
      ) {
        setCustomError("One or more required fields is missing");
        return;
      }

      const structuredData = {
        active: true,
        approved: false,
        first_name,
        last_name,
        age,
        email,
        interests: interests.map((interest) => interest.value),
        languages: languages.map((language) => language.value),
        phone: "+1" + phone,
        webcam: webcam === "yes",
        caregiver: caregiver === "yes",
        address: {
          street_1: "",
          street_2: "",
          city: "",
          country: "",
          zipcode
        },
        times: {
          ...pickerState
        },
        mids: [],
        meeting_created: false,
        communication_preference: communication
      };

      // first create an account using firebase auth
      auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then((user) => {
          analytics().logEvent("sign_up", { method: "email" });

          // if account creation is successful, use the uid
          // and store the data for the user using the uid as the key
          const uid = user.user.uid;
          if (uid != null) {
            return firestore().collection("users").doc(uid).set(structuredData);
          }
          return null;
        })
        .catch((err) => {
          setCustomError(err?.message);
        });
    }
  };
  const password = watch("password");
  //   const first_name = watch("first_name");
  //   console.log(errors);

  //Password confirmation validation
  useEffect(() => {
    if (confirmPswd != null && confirmPswd !== "" && password !== confirmPswd) {
      setPswdError("Passwords do not match");
    } else {
      setPswdError(null);
    }
    // console.log(pswdError);
  }, [confirmPswd, password, pswdError]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>Sign Up</h2>
        <p>
          Before we dine together, we are grateful for the opportunity to get to
          know you!
        </p>
        <p className="marginBottom20">
          But don't worry, we don't share your data with any third parties.
        </p>
        <div className="FormFlex">
          <div className="form__div__new">
            {/* <label htmlFor="first_name">Hi! I'm:</label> */}
            <InputLabel id="label-fname" className="MuiInputLabel-shrink">
              First Name / Preferred Name *
            </InputLabel>
            <TextField
              error={errors?.first_name?.message != null}
              inputRef={register({ required: true })}
              type="text"
              id="first_name"
              name="first_name"
              required
              onChange={(e) => {
                if (e.target.value === "") {
                  setError("first_name", "incorrect", "Name cannot be empty");
                } else {
                  clearError("first_name");
                }
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setError("first_name", "incorrect", "Name cannot be empty");
                } else {
                  clearError("first_name");
                }
              }}
            />
            <FormHelperText>
              {errors?.first_name?.message != null &&
                errors?.first_name?.message}
            </FormHelperText>
          </div>

          <div className="form__div__new">
            <InputLabel id="label-lname" className="MuiInputLabel-shrink">
              Last Name
            </InputLabel>
            <TextField
              inputRef={register}
              type="text"
              id="last_name"
              name="last_name"
            />
          </div>
        </div>

        <div className="FormFlex">
          <div className="form__div__new marginBottom20">
            <InputLabel id="label-age" className="MuiInputLabel-shrink">
              What age range do you belong to? *
            </InputLabel>

            <Select
              id="age"
              name="age"
              labelId="label-age"
              label="Select"
              required
              value={age}
              onChange={(event) => {
                setAge(event.target.value);
              }}
            >
              {/* <option value="">Select</option> */}
              {Object.keys(constants.ageOptionsMap).map((value) => (
                <MenuItem value={value} key={value}>
                  {constants.ageOptionsMap[value]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Users should be at least 18 years old. If you are younger and
              would like to participate, please sign up with your parent!
            </FormHelperText>
          </div>

          <div className="form__div__new">
            <InputLabel id="label-social" className="MuiInputLabel-shrink">
              Social Media Link
            </InputLabel>
            <TextField
              inputRef={register}
              type="text"
              id="social"
              name="social"
              placeholder="https://instagram.com/_mealstogether"
            />
            <FormHelperText>
              If you want to share a social media link, we will use that to
              approve your account. If you prefer that we call you instead to
              approve your identity, leave this blank.
            </FormHelperText>
          </div>
        </div>

        <div className="FormFlex">
          <div className="form__div__new">
            <InputLabel id="label-email" className="MuiInputLabel-shrink">
              Email *
            </InputLabel>
            <TextField
              inputRef={register({ required: true })}
              type="email"
              id="email"
              name="email"
              placeholder="Your username will be your email"
              required
            />
          </div>

          <div className="form__div__new">
            <InputLabel id="label-phone" className="MuiInputLabel-shrink">
              Phone number *
            </InputLabel>
            <TextField
              error={errors?.phone?.message != null}
              inputRef={register({
                required: true,
                pattern: "[0-9]{10}",
                maxLength: 10
              })}
              type="number"
              id="phone"
              name="phone"
              pattern="[0-9]{10}"
              required
              onChange={(e) => {
                if (e.target.value?.length !== 10) {
                  setError(
                    "phone",
                    "incorrect",
                    "Phone number needs to be in the required format"
                  );
                } else {
                  clearError("phone");
                }
              }}
              onBlur={(e) => {
                if (e.target.value?.length !== 10) {
                  setError(
                    "phone",
                    "incorrect",
                    "Phone number needs to be in the required format"
                  );
                } else {
                  clearError("phone");
                }
              }}
            />

            <FormHelperText>Format: 9819605544</FormHelperText>
          </div>
        </div>

        <div className="form__div__new">
          <InputLabel id="label-communication" className="MuiInputLabel-shrink">
            What's your preferred mode of communication?
          </InputLabel>

          <Select
            id="communication"
            name="communication"
            labelId="label-communication"
            required
            value={communication}
            onChange={(event) => {
              setCommunication(event.target.value);
            }}
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="email">Email</MenuItem>
          </Select>
          <FormHelperText>
            After you sign up, this is where we will send you updates!
          </FormHelperText>
        </div>

        <div className="FormFlex">
          <div className="form__div__new">
            <InputLabel id="label-pswd" className="MuiInputLabel-shrink">
              Password *
            </InputLabel>
            <TextField
              inputRef={register({
                required: true,
                pattern: "(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              })}
              type="password"
              id="password"
              name="password"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
              required
            />
            <FormHelperText>
              Choose a password to use along with your email in order to login
              to your account, which will operate like a home base. You'll do
              things like RSVP to dinner and learn adjust your meal time
              availability here.
            </FormHelperText>
          </div>

          <div className="form__div__new">
            <InputLabel id="label-cpswd" className="MuiInputLabel-shrink">
              Confirm Password *
            </InputLabel>
            <TextField
              error={pswdError !== null}
              value={confirmPswd}
              onChange={(e) => setConfirmPswd(e.target.value)}
              type="password"
              id="confpswd"
              name="confpswd"
              required
            />
            <FormHelperText className="error">{pswdError}</FormHelperText>
          </div>
        </div>

        <div className="form__div__new">
          <InputLabel id="label-zcode" className="MuiInputLabel-shrink">
            Zipcode or Postal Code
          </InputLabel>
          <TextField
            inputRef={register}
            type="number"
            min="10000"
            max="99999"
            id="zipcode"
            name="zipcode"
          />
        </div>

        <h3>Matching Preferences</h3>

        <div className="form__div__new">
          <InputLabel id="label-interests" className="MuiInputLabel-shrink">
            Interests *
          </InputLabel>
          <MultiSelect
            value={interests}
            onChange={(selectedOption) => setInterests(selectedOption)}
            options={interestOptions}
            hasSelectAll={false}
            theme={multiSelectTheme}
          />
          <FormHelperText>
            Tell us more about what matters to you and how you spend your time!
          </FormHelperText>
        </div>

        <div className="form__div__new">
          <InputLabel id="label-interests" className="MuiInputLabel-shrink">
            Which languages do you speak? *
          </InputLabel>
          <MultiSelect
            value={languages}
            onChange={(selectedOption) => setLanguages(selectedOption)}
            options={constants.languageOptions}
            hasSelectAll={false}
            theme={multiSelectTheme}
          />
          <FormHelperText>
            Please only choose languages in which you are a fluent speaker.
          </FormHelperText>
        </div>

        <p className="marginBottom20 formHelper">
          Let us know when you're free to eat on a daily basis. To do this,
          click on one of the days <i>(for example Tu is for Tuesday)</i> and
          then highlight (it will turn light blue) the times you're typically
          available that day. The more times you select, the sooner you will be
          partnered! Note that your meal will start on the hour and last 40
          mins.
        </p>

        {/* <p className="marginBottom20 formHelper">
          <i>
            <b>
              Please note the below times are all in Pacific Standard Time
              (PST).
            </b>
          </i>
        </p> */}

        <div className="marginBottom20">
          <TimePicker
            pickerState={pickerState}
            setPickerState={setPickerState}
          />
        </div>
        <h3>Other Questions</h3>
        <div className="form__div__new">
          <FormControl component="fieldset">
            <FormLabel component="legend" className="MuiInputLabel-shrink">
              Are you a caretaker or caregiver to anyone - older or younger?
            </FormLabel>
            <RadioGroup
              aria-label="quiz"
              name="quiz"
              value={caregiver}
              onChange={(e) => {
                setCaregiver(e.target.value);
              }}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
                id="caregiver"
                name="caregiver"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
                id="caregiver"
                name="caregiver"
              />
            </RadioGroup>
            <FormHelperText>
              Traditionally, our nonprofit's efforts are founded on caregiver
              support programs
            </FormHelperText>
          </FormControl>
        </div>

        {/* <!-- Need to add recaptchav2 checkbox js once js is connected. Relevant: https://developers.google.com/recaptcha/docs/display--> */}
        {/* <div class="g-recaptcha" data-sitekey="your_site_key"></div> */}

        <p className="marginBottom20 formHelper">
          <i>
            Disclaimer: I agree that I will not provide or inquire about any
            personal information including, but not limited to personal
            finances, home address or exact location, social security numbers,
            or any other information that is normally deemed confidential. I
            understand that the intention of these phone and video calls are
            purely social. I commit to treating others with respect, even when
            they have different opinions than my own, and will not discriminate
            based on race, gender, sexual orientation, etc. I will report any
            rude or questionable behavior.
          </i>
          <br />
          <br />
          Your information will never be shared with third parties
        </p>
        <div className="form__div__new">
          <input type="submit" className="cool marginBottom20" />

          {customError != null && (
            <Alert
              className="marginBottom20"
              severity="error"
              onClose={() => {
                setCustomError(null);
              }}
            >
              <AlertTitle>Error</AlertTitle>
              {customError}
            </Alert>
          )}
        </div>
      </form>
    </div>
  );
}
