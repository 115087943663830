import React, { useState } from "react";
import "../styles/TimePicker.scss";
import constants from "./constants";
import { renderTime } from "../Utils/timeUtils";
import SelectedTimes from "./SelectedTimes.react";

function TimePicker(props) {
  const { pickerOptions, times } = constants;
  const { pickerState, setPickerState } = props;

  const [selectedDay, setSelectedDay] = useState("monday");

  return (
    <div className="timePickerContainer">
      <div className="timePicker">
        <div className="timePicker__days">
          {Object.keys(pickerOptions).map(day => (
            <div
              key={day}
              role="button"
              onClick={() => setSelectedDay(day)}
              className={`timePicker__day ${
                day === selectedDay ? "active" : ""
              }`}
            >
              {day.substr(0, 2)}
            </div>
          ))}
        </div>
        <div className="timePicker__times">
          {Object.keys(times).map(time => (
            <div
              key={time}
              role="button"
              onClick={() =>
                setPickerState({
                  ...pickerState,
                  [selectedDay]: {
                    ...pickerState[selectedDay],
                    [time]: !pickerState[selectedDay][time]
                  }
                })
              }
              className={`timePicker__time ${
                pickerState[selectedDay][time] ? "active" : ""
              }`}
            >
              {renderTime(time)}
            </div>
          ))}
        </div>
      </div>
      <SelectedTimes pickerState={pickerState} />
    </div>
  );
}

export default TimePicker;

// function renderTime(time) {
// const now = moment.tz(moment().format(), "America/Los_Angeles");
// return `${moment
//   .tz(
//     now
//       .clone()
//       .hour(time)
//       .format(),
//     "America/Los_Angeles"
//   )
//   .format("ha")} - ${moment
//   .tz(
//     now
//       .clone()
//       .hour(time)
//       .add("1", "hour")
//       .format(),
//     "America/Los_Angeles"
//   )
//   .format("ha")}`;
//   time = Number(time);
//   if (time < 12) {
//     return `${time}AM - ${time + 1}${time + 1 < 12 ? "AM" : "PM"}`;
//   } else if (time === 12) {
//     return "12PM - 1PM";
//   } else {
//     return `${time - 12}PM - ${time - 12 + 1}PM`;
//   }
// }
// function renderTimeInYourTimeZone(time) {
//   const now = moment.tz(moment().format(), "America/Los_Angeles");
//   const floorTime = moment
//     .tz(
//       now
//         .clone()
//         .hour(time)
//         .format(),
//       "America/New_York"
//     )
//     .format("ha");
//   const cielTime = moment
//     .tz(
//       now
//         .clone()
//         .hour(time)
//         .add("1", "hour")
//         .format(),
//       "America/New_York"
//     )
//     .format("ha");
//   return `${floorTime} - ${cielTime} ${
//     moment
//       .tz(
//         now
//           .clone()
//           .hour(time)
//           .add("1", "hour")
//           .format(),
//         "America/New_York"
//       )
//       .day() >
//     moment
//       .tz(
//         now
//           .clone()
//           .hour(time)
//           .add("1", "hour")
//           .format(),
//         "America/Los_Angeles"
//       )
//       .day()
//       ? "+1"
//       : ""
//   }`;
//   time = Number(time);
//   if (time < 12) {
//     return `${time}AM - ${time + 1}${time + 1 < 12 ? "AM" : "PM"}`;
//   } else if (time === 12) {
//     return "12PM - 1PM";
//   } else {
//     return `${time - 12}PM - ${time - 12 + 1}PM`;
//   }
// }
