const moment = require("moment-timezone");

function renderTime(time) {
  const timezone = moment.tz.guess();
  const now = moment.tz(moment().format(), "America/Los_Angeles");
  return `${moment
    .tz(
      now
        .clone()
        .hour(time)
        .format(),
      timezone
    )
    .format("ha")} - ${moment
    .tz(
      now
        .clone()
        .hour(time)
        .add("1", "hour")
        .format(),
      timezone
    )
    .format("ha")}`;
  time = Number(time);
  if (time < 12) {
    return `${time}AM - ${time + 1}${time + 1 < 12 ? "AM" : "PM"}`;
  } else if (time === 12) {
    return "12PM - 1PM";
  } else {
    return `${time - 12}PM - ${time - 12 + 1}PM`;
  }
}

function renderTimeMinimal(time) {
  const timezone = moment.tz.guess();
  const now = moment.tz(moment().format(), "America/Los_Angeles");
  return `${moment
    .tz(
      now
        .clone()
        .hour(time)
        .format(),
      timezone
    )
    .format("ha")}`;
  time = Number(time);
  if (time < 12) {
    return `${time}AM`;
  } else if (time === 12) {
    return "12PM";
  } else {
    return `${time - 12}PM`;
  }
}

// function renderTime(time) {
//   const now = moment.tz(moment().format(), "America/Los_Angeles");
//   return `${moment
//     .tz(
//       now
//         .clone()
//         .hour(time)
//         .format(),
//       "America/Los_Angeles"
//     )
//     .format("ha")} - ${moment
//     .tz(
//       now
//         .clone()
//         .hour(time)
//         .add("1", "hour")
//         .format(),
//       "America/Los_Angeles"
//     )
//     .format("ha")}`;
// }
// function renderTimeInYourTimeZone(time) {
//   const now = moment.tz(moment().format(), "America/Los_Angeles");
//   const floorTime = moment
//     .tz(
//       now
//         .clone()
//         .hour(time)
//         .format(),
//       "America/New_York"
//     )
//     .format("ha");
//   const cielTime = moment
//     .tz(
//       now
//         .clone()
//         .hour(time)
//         .add("1", "hour")
//         .format(),
//       "America/New_York"
//     )
//     .format("ha");
//   return `${floorTime} - ${cielTime} ${
//     moment
//       .tz(
//         now
//           .clone()
//           .hour(time)
//           .add("1", "hour")
//           .format(),
//         "America/New_York"
//       )
//       .day() >
//     moment
//       .tz(
//         now
//           .clone()
//           .hour(time)
//           .add("1", "hour")
//           .format(),
//         "America/Los_Angeles"
//       )
//       .day()
//       ? "+1"
//       : ""
//   }`;
//   time = Number(time);
//   if (time < 12) {
//     return `${time}AM - ${time + 1}${time + 1 < 12 ? "AM" : "PM"}`;
//   } else if (time === 12) {
//     return "12PM - 1PM";
//   } else {
//     return `${time - 12}PM - ${time - 12 + 1}PM`;
//   }
// }

module.exports = {
  renderTime,
  renderTimeMinimal
};
