import React from "react";
import "../styles/HomePage.scss";

const bgBlob = (
  <svg viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(300, 300)">
      <path
        d="M116.8,-90.5C149.1,-52.6,171.6,-4.1,165.8,44.5C160,93.2,126,142.1,82,158.9C38,175.7,-15.899999999999999,160.4,-62.3,135.6C-108.6,110.9,-147.4,76.7,-152.9,38.9C-158.3,1.1000000000000014,-130.4,-40.3,-99.3,-77.9C-68.3,-115.5,-34.1,-149.2,4,-152.4C42.2,-155.7,84.4,-128.3,116.8,-90.5Z"
        fill="#e8e5e3"
      ></path>
    </g>
  </svg>
);

export default function TeamPage(props) {
  function cropName(text) {
    if (text.length > 12 && text.split(" ").length > 1) {
      return text.replace(/(\B[^ ]*)$/g, ".");
    }
    return text;
  }

  function cropInit(text) {
    if (text === "" || text === "idk") {
      return "Multi-tasking";
    }
    if (text.length > 12 && text.split(" ").length > 1) {
      return text.replace(/((, ).+)+$/g, " & more");
    }
    return text;
  }

  return (
    <div className="member_each" data-aos="fade-up" onClick={props.click}>
      <div className="member_img_container">
        {bgBlob}
        <img src={props.pic} alt={`${props.name}`} />
      </div>
      <div className="member_text_outer">
        <div className="member_text_inner">
          <h3>{cropName(props.name)}</h3>
          <p>{cropInit(props.init)}</p>
        </div>
      </div>
    </div>
  );
}
