import React from "react";
import "../styles/HomePage.scss";
import MemberCard from "./MemberCard.react";

import Dialog from "@material-ui/core/Dialog";

import memberA from "../images/team/male.svg";
import memberB from "../images/team/female.svg";
import giovanna from "../images/team/giovanna.jpg";
import hritik from "../images/team/hritik.jpeg";
import grace from "../images/team/grace.jpg";
import vera from "../images/team/vera.jpg";
import ashten from "../images/team/ashten.jpg";
import natashia from "../images/team/natashia.jpg";
import nidhi from "../images/team/nidhi.jpg";
import nihal from "../images/team/nihal.jpg";
import rishabh from "../images/team/rishabh.jpg";
import sharanya from "../images/team/sharanya.jpg";
import shyam from "../images/team/shyam.jpg";

export default function TeamPage() {
	const members = [
		{
			name: "Robert Egger",
			init: "Business Plan Developer",
			reason: "idk",
			linkedin: "idk",
			pic: memberA,
			email: "idk",
		},
		{
			name: "Norma Bostarr",
			init: "Operations",
			reason:
				"We, are responsible for building a future that is inclusive of everyone, particularly those who exist on the fringes. Previously, society did not necessarily innovate or build technology with older adults in mind. MealsTogether is an opportunity to re-think how we can build technology in a way that is inclusive. It is important to speak with older adults to learn their goals: what they need, want, & are comfortable with. From there we can build technology that is truly inclusive.",
			linkedin: "norma-bostarr",
			pic: memberB,
			email: "nbostarr@gmail.com",
		},
		{
			name: "Nihal Satyadev",
			init: "Development, Design, Infrastructure",
			reason:
				"Generations are naturally meant to be deeply interconnected. A thriving society is one that is structured around the net benefits that older and younger generations can offer to each other. In particular, COVID-19 has made our shared need for connection more evident than ever before. Technology has the best opportunity to connect people and this initiative is one that I hope will bridge generations together like none before it.",
			linkedin: "nihal-satyadev-mph-ab166485",
			pic: nihal,
			email: "nihal@theyouthmovement.org",
		},
		{
			name: "Natashia Townsend",
			init: "Operations, Communication  Content, Outreach, Execution",
			reason:
				"I am very passionate about helping others and ensuring that my work is meaningful. During this time when many people are stressed due to the restrictive measures that COVID-19 imposes on our everyday lives, I want to help and contribute to the greatest good for everyone. In this moment when social isolation is at its peak, we should all do our best to connect with those around us to let them know that we are all in this together.",
			linkedin: "natashiatownsend",
			pic: natashia,
			email: "Natashia@theyouthmovement.org",
		},
		{
			name: "Rishabh Aggarwal",
			init: "Development, Design",
			reason:
				"Being alone in my home during the pandemic, helped me empathize with all the people who face loneliness throughout most their old age. I saw this as an amazing chance to help people combat this loneliness by connecting them with someone to share a meal with as well as help design and develop a simple and delightful experience for the elderly and youth alike.",
			linkedin: "rishabhaggarwal2",
			pic: rishabh,
			email: "idk",
		},
		{
			name: "Nidhi Satyadev",
			init: "Social Media Content",
			reason:
				"Covid -19 has created so much fear and loneliness that MealsTogether strives to ease as it allows for different generations to talk and get through this tough time together. When I was asked to be a part of this amazing idea, I was beyond happy to do my small part to contribute.",
			linkedin: "nidhi-satyadev-145349177",
			pic: nidhi,
			email: "Nidhisatyadev@gmail.com",
		},
		{
			name: "Heena Doshi",
			init: "Copy Writing",
			reason:
				"Although I am months away from completing a Masters in Public Health, I've felt completely helpless during this pandemic. Working on MealsTogether feels like a small way to help keep the world fighting and moving forward in the most positive way.",
			linkedin: "heena-doshi-82b76477",
			pic: memberB,
			email: "idk",
		},
		{
			name: "Pronit Dutta",
			init: "Social Media Content",
			reason: "idk",
			linkedin: "idk",
			pic: memberA,
			email: "idk",
		},
		{
			name: "Baraa Hijaz",
			init: "Social Media Content",
			reason: "idk",
			linkedin: "idk",
			pic: memberB,
			email: "idk",
		},
		{
			name: "Zack Khan",
			init: "Marketing",
			reason: "idk",
			linkedin: "idk",
			pic: memberA,
			email: "idk",
		},
		{
			name: "Vera Drapers",
			init: "Design",
			reason:
				"I joined Meal Together because I knew that with the virus, right now can be a very stressful and lonely time for people. Staying connected with your community is very important in times like these and can have such a positive impact on people.",
			linkedin: "vera-drapers",
			pic: vera,
			email: "vera.drapers@outlook.com",
		},
		{
			name: "Grace Sturlaugson",
			init: "Design",
			reason:
				"I felt that MealsTogether was a unique experience that allowed us to focus on designing for people who really need it. It is scary to be alone in this odd time, so helping with this website was a way to help people feel a little less alone. More than ever, an inclusive online space is necessary.",
			linkedin: "graceel",
			pic: grace,
			email: "graceel@uw.edu",
		},
		{
			name: "Ashten Alexander",
			init: "Design",
			reason:
				"I joined because targeting the generation gap is a huge interest of mind. Having worked previously in a retirement home, I understand how loneliness affects the elderly and wanted to make a difference to combat that problem.",
			linkedin: "ashtenalexander",
			pic: ashten,
			email: "alexaa24@uw.edu",
		},
		{
			name: "Sam Chai",
			init: "Design",
			reason: "idk",
			linkedin: "idk",
			pic: memberB,
			email: "idk",
		},
		{
			name: "Alan Enriquez",
			init: "Design",
			reason: "idk",
			linkedin: "idk",
			pic: memberA,
			email: "idk",
		},
		{
			name: "Michaela Fiasova",
			init: "Design",
			reason: "idk",
			linkedin: "mfiasova",
			pic: memberA,
			email: "idk",
		},
		{
			name: "Eva Frye",
			init: "Brand Strategy",
			reason: "idk",
			linkedin: "evafrye",
			pic: memberA,
			email: "idk",
		},
		{
			name: "Malik Adán",
			init: "Copy Writing",
			reason:
				"A friend asked for my help contributing work to projects that were trying to have real impact during this covid19 crisis. MealsTogether fit the description, while anticipating a problem that we'll be dealing with for years to come. I was more than happy to help.",
			linkedin: "malikbs",
			pic: memberB,
			email: "idk",
		},
		{
			name: "Nasim",
			init: "idk",
			reason: "idk",
			linkedin: "idk",
			pic: memberB,
			email: "idk",
		},
		{
			name: "Lourdes Bustamante",
			init: "Communication Content",
			reason: "idk",
			linkedin: "idk",
			pic: memberA,
			email: "idk",
		},
		{
			name: "Shyam Hassan",
			init: "Communication Content",
			reason:
				"COVID-19 has placed stress on us all as we must self isolate for safety and many are worried about future economic prospects. These pressures are made worse when we do not have regular social interaction. This impacts all generations, but most significantly impacts older adults who now may go for long periods alone. We need to turn to intergenerational solutions to help older adults maintain social support and for our on health as well. Being alone for long periods can be harmful to one's mental health and this is a factor we cannot neglect as we tackle the COVID-19 crisis. MealsTogether provides the kind of innovative approach we need to help our generations help one another.",
			linkedin: "shyamhassan",
			pic: shyam,
			email: "shyam@theyouthmovement.org",
		},
		{
			name: "Katherine Rose",
			init: "idk",
			reason: "idk",
			linkedin: "idk",
			pic: memberB,
			email: "idk",
		},
		{
			name: "Dongjian Jin",
			init: "idk",
			reason: "idk",
			linkedin: "idk",
			pic: memberB,
			email: "idk",
		},
		{
			name: "Giovanna Manson-Hing",
			init: "Outreach",
			reason:
				"I thought MealsTogether would be a very innovative way of a small gesture having a large impact on everyone experiencing a yearn to interact with others. Volunteering on this project designed to build community among all generations and to promote interactions with one another was a no-brainer. It always feels good meeting new people.",
			linkedin: "giovanna-manson-hing",
			pic: giovanna,
			email: "Giovanna@theyouthmovement.org",
		},
		{
			name: "Sharanya Suresh",
			init: "Outreach, Marketing",
			reason:
				"Social distancing doesn’t need to mean emotional distancing. Talking to a stranger can be very therapeutic during this uncertain time and I wanted to do my part to help isolated individuals like myself to feel a little more connected to the world through our MealsTogether program.",
			linkedin: "sharanya-suresh-95aa00140",
			pic: sharanya,
			email: "sharanya@theyouthmovement.org",
		},
		{
			name: "Hritik Aggarwal",
			init: "Development, Design",
			reason:
				"During such uncertain times, I believe that even a small step towards helping someone can create a positive impact on their lives. With MealsTogether, I found the perfect opportunity to volunteer for something that affects such a large community and helps them in this time of isolation.",
			linkedin: "hritikaggarwal",
			pic: hritik,
			email: "hritikaggarwal2@gmail.com",
		},
	];

	const [open, setOpen] = React.useState(false);

	const [currName, setCurrName] = React.useState();
	const [currInit, setCurrInit] = React.useState();
	const [currReason, setCurrReason] = React.useState();
	const [currLinkedIn, setCurrLinkedIn] = React.useState();
	const [currEmail, setCurrEmail] = React.useState();

	const handleClose = () => {
		setOpen(false);
	};

	function switchInit(text) {
		if (text === "" || text === "idk") {
			return "Multi-tasking";
		}
		return text;
	}

	function switchReason(text) {
		if (text === "" || text === "idk") {
			return "";
		}
		return text;
	}

	function switchLinkedIn(text) {
		if (text === "" || text === "idk") {
			return "";
		}
		return text;
	}

	function switchEmail(text) {
		if (text === "" || text === "idk") {
			return "";
		}
		return text;
	}

	const clickHandler = (index) => {
		setCurrName(members[index].name);
		setCurrInit(switchInit(members[index].init));
		setCurrReason(switchReason(members[index].reason));
		setCurrLinkedIn(switchLinkedIn(members[index].linkedin));
		setCurrEmail(switchEmail(members[index].email));
		setOpen(true);
	};

	return (
		<div className="home">
			<div className="splash member">
				<div className="shared_container splash_container">
					<h1 data-aos="fade-up">
						Meet
						<br /> our Team
					</h1>
					<p data-aos="fade-up" data-aos-delay="200">
						Coming together from around the world to create
						intergenerational dinner parties.
					</p>
				</div>
			</div>

			<div className="MembersOuterContainer ContainerWhite ContainerCenter">
				<div className="shared_container members_inner_container">
					{members.map((key, index) => (
						<MemberCard
							{...key}
							key={index}
							click={() => clickHandler(index)}
						/>
					))}
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<div className="dialogueContainer">
							<h4>{currName}</h4>
							<h5>{currInit}</h5>
							{currReason !== "" ? <p>{currReason}</p> : null}
							{currLinkedIn !== "" ? (
								<a
									href={`https://www.linkedin.com/in/${currLinkedIn}`}
								>
									LinkedIn
								</a>
							) : null}
						</div>
					</Dialog>
				</div>
			</div>

			<div className="Need ContainerPrimary">
				<div className="shared_container Need_container">
					<div className="NeedContent">
						<h2 data-aos="fade-up">Join the Team</h2>
						<p data-aos="fade-up">
							Like the work we do? Come collaborate on this tool
							with us, and share your experiences with the whole
							team!
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
