import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { InputLabel, TextField, FormHelperText } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { auth, analytics } from "firebase";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";

export default function Login() {
  const { register, handleSubmit, watch, errors } = useForm();
  const [error, setError] = useState(null);

  const onSubmit = data => {
    const { email, password } = data;

    auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        analytics().logEvent("login", {});
      })
      .catch(err => {
        console.log(err);
        setError(err);
        // confirmAlert({
        //   message: err?.message
        // });
      });
  };

  console.log(error);
  return (
    <div className="LoginPage">
      <div className="shared_container LoginPageContainer">
        {error != null ? (
          <Alert
            className="marginBottom20"
            severity="error"
            onClose={() => {
              setError(null);
            }}
          >
            <AlertTitle>Error</AlertTitle>
            {error?.message}
          </Alert>
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="marginBottom20">Login</h2>
          <div className="form__div__new">
            <InputLabel id="label-email" className="MuiInputLabel-shrink">
              Email *
            </InputLabel>
            <TextField
              inputRef={register({ required: true })}
              type="email"
              id="email"
              name="email"
              placeholder="Your username will be your email"
              required
            />
          </div>
          <div className="form__div__new">
            <InputLabel id="label-pswd" className="MuiInputLabel-shrink">
              Password *
            </InputLabel>
            <TextField
              inputRef={register({ required: true })}
              type="password"
              id="password"
              name="password"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
              required
            />
          </div>
          <div className="form__div__new">
            <input type="submit" className="long outline" />
          </div>
          <div className="form__div__new">
            <Link to="/forgot" className="simple">
              <FormHelperText>Forgot Password?</FormHelperText>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
