import React from "react";
import MatchFound from "./MatchFound.react";
import { useState } from "react";
import SelectedTimes from "./SelectedTimes.react";
const monitor = require("../images/monitor.png");
const phone = require("../images/phone.png");

// import SignUp from "./SignUp.react";

export default function LoggedInPage({ userInfo }) {
  if (userInfo?.active === false) {
    return (
      <div className="loggedInPage">
        <div className="loggedInPage_dashboard">
          <h3>Account Deactivated</h3>
        </div>
      </div>
    );
  }
  return (
    <div className="loggedInPage">
      <div className="loggedInPage_dashboard">
        {userInfo.approved !== true && <AwaitingApproval userInfo={userInfo} />}
        {userInfo.approved === true && userInfo.meeting_created !== true && (
          <AwaitingMatch userInfo={userInfo} />
        )}
        {userInfo.approved === true && userInfo.meeting_created === true && (
          <MatchFound userInfo={userInfo} />
        )}
      </div>
      <div className="loggedInPage_howto">
        <HowTo />
      </div>
      <Starter />
    </div>
  );
}

function AwaitingApproval({ userInfo }) {
  return (
    <div className="shared_container loggedInPage_container">
      <h2 className="text">Nice to Meet You: Awaiting Account Approval</h2>
      <p className="text">
        {userInfo.first_name}, thank you for signing up for MealsTogether! Your
        security is our priority - we are verifying you are who you say you are!
      </p>
    </div>
  );
}

function AwaitingMatch({ userInfo }) {
  return (
    <div className="shared_container loggedInPage_container">
      <h2 className="text">Awaiting match with your next FoodFriend</h2>
      <p className="text marginBottom20">
        {Array.isArray(userInfo.mids) && userInfo.mids.length > 0
          ? `We are finding a new FoodFriend for you to share a meal
        with based on similar availability and interests.`
          : `${userInfo.first_name}, your identity has been confirmed - it’s great to
        meet you! We are currently finding a FoodFriend for you to share a meal
        with based on similar availability and interests.`}
      </p>

      <h3>Selected Times in PST</h3>
      <SelectedTimes pickerState={userInfo.times} />

      {/* <p>In the meantime,</p>

      <div className="awaitingMatch_tips">
        <div className="awaitingMatch_tip">
          <div className="awaitingMatch_image"></div>
          <h4>Make sure you have a working webcam</h4>
        </div>

        <div className="awaitingMatch_tip">
          <div className="awaitingMatch_image"></div>
          <h4>Make sure you have zoom downloaded</h4>
        </div>
      </div> */}
    </div>
  );
}

function HowTo() {
  const [device, setDevice] = useState("");
  const [platform, setPlatform] = useState("");
  return (
    <div className="shared_container howTo_container">
      <h2 className="text">Learn how to:</h2>
      <p>1. Download Zoom</p>
      <p className="marginBottom20">2. Join a zoom meeting</p>
      <p className="marginBottom20">
        Learning how to use zoom is just like following a recipe, we'll help
        break it down for you.
      </p>
      <h3 className="marginBottom20">I WILL USE</h3>
      <div className="howToOptions marginBottom20">
        {[
          { key: "computer", value: "my computer", image: monitor },
          { key: "phone", value: "my phone", image: phone }
        ].map(({ key, value, image }) => (
          <div
            key={key}
            className={`howToOption ${device === key ? "active" : ""}`}
            onClick={() => {
              setDevice(key);
            }}
          >
            <img src={image} alt={key}></img>
            <p>{value}</p>
          </div>
        ))}
      </div>
      {device != null && <h3 className="marginBottom20">{device}</h3>}

      {device === "computer" && (
        <div className="howToOptions marginBottom20">
          {[
            { key: "mac", value: "my mac", image: monitor },
            { key: "pc", value: "my PC", image: monitor }
          ].map(({ key, value, image }) => (
            <div
              className={`howToOption ${platform === key ? "active" : ""}`}
              onClick={() => {
                setPlatform(key);
              }}
            >
              <img src={image} alt={key}></img>
              <p>{value}</p>
            </div>
          ))}
        </div>
      )}

      {device === "phone" && (
        <div className="howToOptions marginBottom20">
          {[
            { key: "iphone", value: "my iphone", image: phone },
            { key: "android", value: "my android", image: phone }
          ].map(({ key, value, image }) => (
            <div
              className={`howToOption ${platform === key ? "active" : ""}`}
              onClick={() => {
                setPlatform(key);
              }}
            >
              <img src={image} alt={key}></img>
              <p>{value}</p>
            </div>
          ))}
        </div>
      )}

      <div className="howTo_contents marginBottom20">
        {platform !== "" &&
          {
            mac: [
              {
                video_url: "https://www.youtube.com/embed/jYbKvVqI0eY",
                title: "1. Download zoom on a Mac",
                link: "/how-to-mac"
              },
              {
                video_url: "https://www.youtube.com/embed/YI_iXreRXmc",
                title: "2. Join a zoom meeting on a Mac",
                link: "/how-to-mac"
              }
            ],
            pc: [
              {
                video_url: "https://www.youtube.com/embed/DLS1pYyzUh8",
                title: "1. Download zoom on a PC",
                link: "/how-to-pc"
              },
              {
                video_url: "https://www.youtube.com/embed/hIkCmbvAHQQ",
                title: "2. Join a zoom meeting on a PC",
                link: "/how-to-pc"
              }
            ],
            iphone: [
              {
                video_url: "https://www.youtube.com/embed/ZtGUuJMvxhI",
                title: "1. Download zoom on an iPhone",
                link: "/how-to-iphone"
              },
              {
                video_url: "https://www.youtube.com/embed/nXnvFuUY2wY",
                title: "2. Join a zoom meeting on an iPhone",
                link: "/how-to-iphone"
              }
            ],
            android: [
              {
                video_url: "https://www.youtube.com/embed/jCX0pIut8gM",
                title: "1. Download zoom on an Android",
                link: "/how-to-android"
              },
              {
                video_url: "https://www.youtube.com/embed/jCX0pIut8gM",
                title: "2. Join a zoom meeting on an Android",
                link: "/how-to-android"
              }
            ]
          }[platform].map((obj, index) => (
            <div className="howTo_content" key={index}>
              <div className="howTo_video">
                <iframe
                  src={obj.video_url}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="video"
                />
              </div>
              <div className="howTo_details">
                <h3 className="text">{obj.title}</h3>
                {/* <p className="text">{obj.desc}</p> */}
              </div>
            </div>
          ))}
      </div>
      <p>
        Were these helpful? We don't like to assume we know how you learn -
        please let us know where you got stuck or what would make the process
        more clear so we can improve our resources for future MealsTogether
        FoodFriends.
      </p>
    </div>
  );
}

function Starter() {
  return (
    <div className="ConvoStarterContainer ContainerPale ">
      <div className="shared_container">
        <div className="ConvoStarterContent">
          <h2 data-aos="fade-up">
            Need some <br /> conversation starters
          </h2>
          <ul className="marginBottom20" data-aos="fade-up">
            <li>What are you having for your meal today?</li>
            <li>
              If you could eat one thing for the rest of your life, what would
              it be?
            </li>
            <li>What is your favorite book?</li>
            <li>What’s the best advice you have ever received?</li>
            <li>What was your favorite thing to do when you were a kid?</li>
            <li>Do you have a favorite tv show or movie?</li>
            <li>How many siblings do you have? </li>
            <li>What is something that made you happy this week?</li>
            <li>
              Out of all of your accomplishments, what are you most proud of?
            </li>
            <li>
              Do you have any pets? If not, did you have any pets while growing
              up?
            </li>
            <li>Where is your favorite travel destination?</li>
            <li>
              What is your favorite sport and who is your favorite player?
            </li>
            <li>
              What advice would you give to someone who is just starting out in
              life?
            </li>
            <li>Did you attend college? What did you study?</li>
            <li>
              Have you participated in a MealsTogether before? How’d it go?
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
