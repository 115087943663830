import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { InputLabel, TextField, FormHelperText } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { auth, analytics } from "firebase";
import { Link } from "react-router-dom";

export default function Forgot() {
  const { register, handleSubmit, watch, errors } = useForm();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const onSubmit = data => {
    const { email } = data;

    auth()
      .sendPasswordResetEmail(email)
      .then(val => {
        console.log(val);
        setSuccess({
          message: `A password reset email has been sent to your email account ${email}`
        });
      })
      .catch(err => {
        console.log(err);
        setError(err);
        // confirmAlert({
        //   message: err?.message
        // });
      });
  };

  console.log(error);
  return (
    <div className="LoginPage">
      <div className="shared_container LoginPageContainer">
        {success != null ? (
          <Alert
            className="marginBottom20"
            severity="success"
            onClose={() => {
              setSuccess(null);
            }}
          >
            <AlertTitle>Confirmation Email sent</AlertTitle>
            {success?.message}
          </Alert>
        ) : null}
        {error != null ? (
          <Alert
            className="marginBottom20"
            severity="error"
            onClose={() => {
              setError(null);
            }}
          >
            <AlertTitle>Error</AlertTitle>
            {error?.message}
          </Alert>
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form__div__new">
            <h3 className="marginBottom20">Forgot Password</h3>
            <p>Please enter the email address you used to sign up</p>
          </div>

          <div className="form__div__new">
            <InputLabel id="label-email" className="MuiInputLabel-shrink">
              Email *
            </InputLabel>
            <TextField
              inputRef={register({ required: true })}
              type="email"
              id="email"
              name="email"
              placeholder="Your username will be your email"
              required
            />
          </div>

          <div className="form__div__new">
            <input type="submit" className="long outline" />
          </div>
        </form>
      </div>
    </div>
  );
}
