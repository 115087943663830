import React from "react";

import { firestore, auth } from "firebase";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import constants from "./constants";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import Rating from "@material-ui/lab/Rating";

const matchInfoBase =
  process.env.NODE_ENV === "test" ||
  process.env.NODE_ENV === "development" ||
  (process.env.REACT_APP_IS_STAGING != null &&
    process.env.REACT_APP_IS_STAGING.toString() === "1")
    ? "https://us-central1-e-meal-staging.cloudfunctions.net/getMatchInfo?"
    : "https://us-central1-e-meal-161a9.cloudfunctions.net/getMatchInfo?";

export default function MatchFound({ userInfo }) {
  const [user] = useAuthState(auth());
  const [matchInfo, setMatchInfo] = useState(null);
  const [matchStatus, setMatchStatus] = useState(null);
  const [yourStatus, setYourStatus] = useState(null);
  const [yourRating, setYourRating] = useState(null);
  const [meetingInfo, setMeetingInfo] = useState(null);
  const [mid, setMid] = useState(null);
  const [actions, setActions] = useState(0);
  const timeElapsed =
    meetingInfo != null
      ? (new Date() - new Date(meetingInfo.meet_time)) /
        constants.milisecondToMinutes
      : 0;
  //   const timeElapsed = 90;

  useEffect(() => {
    if (
      userInfo.meeting_created &&
      Array.isArray(userInfo.mids) &&
      userInfo.mids.length > 0
    ) {
      const mid = userInfo.mids[userInfo.mids.length - 1];
      setMid(mid);
      firestore()
        .collection("meetings")
        .doc(mid)
        .get()
        .then(meetingDoc => {
          if (meetingDoc.exists) {
            setMeetingInfo(meetingDoc.data());
          }
        });
    }
  }, [userInfo, actions]);

  // TODO: This needs to be a fetch from server function instead of direct access in
  // order to hide private information
  useEffect(() => {
    if (
      meetingInfo != null &&
      meetingInfo.user1 != null &&
      meetingInfo.user2 != null
    ) {
      setMatchStatus(
        meetingInfo.user1.id === user.uid
          ? meetingInfo.user2.status
          : meetingInfo.user1.status
      );
      setYourStatus(
        meetingInfo.user1.id === user.uid
          ? meetingInfo.user1.status
          : meetingInfo.user2.status
      );
      setYourRating(
        meetingInfo.user1.id === user.uid
          ? meetingInfo.user1.rating_id
          : meetingInfo.user2.rating_id
      );
      fetch(`${matchInfoBase}meeting_id=${mid}&user_id=${user.uid}`)
        .then(resp => {
          return resp.json();
        })
        .then(json => {
          setMatchInfo(json);
        });
    }
  }, [user, meetingInfo, mid]);

  const onConfirm = () => {
    const userIdName = meetingInfo.user1.id === user.uid ? "user1" : "user2";
    firestore()
      .collection("meetings")
      .doc(mid)
      .update({
        [userIdName]: {
          id: user.uid,
          status: "confirmed",
          rating_id: yourRating
        }
      })
      .then(() => {
        setActions(actions + 1);
      });
  };

  const onCancel = () => {
    const userIdName = meetingInfo.user1.id === user.uid ? "user1" : "user2";
    confirmAlert({
      title: "Are you sure you want to cancel the meeting?",
      message:
        "By cancelling, you will be put in the matching queue again to be matched with another FoodFriend. If you wish to stop getting matches, please deactivate your account from the profile.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            firestore()
              .collection("meetings")
              .doc(mid)
              .update({
                [userIdName]: {
                  id: user.uid,
                  status: "canceled",
                  rating_id: yourRating
                }
              })
              .then(() => {
                setActions(actions + 1);
              });
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  };

  if (meetingInfo == null) {
    return null;
  }
  return (
    <div className="shared_container loggedInPage_container">
      <h2 className="text">
        {timeElapsed > 30
          ? actions > 0
            ? "Thanks for leaving a review"
            : "Please leave a review about your meeting"
          : "FoodFriend Match Found!"}
      </h2>
      <p className="text">
        {timeElapsed > 30
          ? `Hope you had a delightful meal. Let us know if you liked meeting
            them.`
          : meetingInfo.scheduled === true
          ? `${userInfo.first_name}, You're Meal is Scheduled
You both have confirmed that you'll be dining together. Don't forget to mark your calendar and return here just before your scheduled meal time so you can join via Zoom.`
          : `You have been matched with a FoodFriend. You will both need to confirm
        the meeting in the 24 hours after the match is made to get the video
        call details.`}
      </p>
      <div className="MatchFoundFlex">
        {timeElapsed > 30 && meetingInfo != null && (
          <MatchRating
            actions={actions}
            prec
            setActions={setActions}
            userInfo={userInfo}
            meetingInfo={meetingInfo}
          />
        )}

        {matchInfo != null && meetingInfo != null && (
          <div className="matchInfoContainer">
            <h2>
              {matchInfo.first_name} {matchInfo.last_name}
            </h2>

            <div className="matchInfo">
              <h3>
                {timeElapsed > 60
                  ? "Meeting has ended"
                  : meetingInfo.scheduled === true
                  ? "Meeting has been confirmed and scheduled"
                  : "Meeting is awaiting confirmation"}
              </h3>
            </div>

            <div className="matchInfo">
              <h3>Age Range</h3>
              <p>
                {matchInfo.age != null &&
                constants.ageOptionsMap.hasOwnProperty(matchInfo.age)
                  ? constants.ageOptionsMap[matchInfo.age]
                  : null}
              </p>
            </div>

            <div className="matchInfoFlex">
              <div className="matchInfo">
                <h3>Interests</h3>

                {matchInfo.interests.map(interest => (
                  <p key={interest}>
                    {constants.interestOptionsMap.hasOwnProperty(interest)
                      ? constants.interestOptionsMap[interest]
                      : constants.interestOptionsMapLegacy[interest]}
                  </p>
                ))}
              </div>
              <div className="matchInfo languages">
                <h3>Langugages</h3>

                {matchInfo.languages.map(language => (
                  <p key={language}>{language}</p>
                ))}
              </div>
            </div>
            <div className="matchInfo">
              <h3>Meeting Time</h3>

              {timeElapsed > 60 && <p>Meeting has finished</p>}

              <p>
                {new Date(meetingInfo.meet_time).toLocaleString()} Los Angeles
                Time
              </p>
            </div>
            <div className="matchInfo">
              <h3>Confirmations</h3>
              {yourStatus === "waiting" ? (
                <p>Awaiting your confirmation</p>
              ) : yourStatus === "canceled" ? (
                <p>You have declined your meal</p>
              ) : (
                <p>You have confirmed your meal</p>
              )}
              {matchStatus === "waiting" ? (
                <p>{`Awaiting ${matchInfo.first_name}'s confirmation`}</p>
              ) : matchStatus === "canceled" ? (
                <p>{matchInfo.first_name} has declined your meal</p>
              ) : (
                <p>{matchInfo.first_name} has confirmed your meal</p>
              )}
            </div>
            {timeElapsed < 80 && (
              <div className="matchInfo">
                {yourStatus === "waiting" && (
                  <button className="cool long" onClick={onConfirm}>
                    Confirm Meeting
                  </button>
                )}
                {yourStatus === "confirmed" &&
                  meetingInfo.scheduled === true &&
                  meetingInfo.zoom_meeting != null && (
                    <a
                      className="cool long"
                      href={meetingInfo.zoom_meeting}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                    >
                      Join Zoom Meeting
                    </a>
                  )}
              </div>
            )}
            {timeElapsed < 0 && yourStatus === "waiting" && (
              <div className="matchInfo">
                <button className="long" onClick={onCancel}>
                  Cancel Meeting
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function MatchRating({ userInfo, meetingInfo, actions, setActions }) {
  const [user] = useAuthState(auth());
  const { register, handleSubmit, watch, errors } = useForm();
  const [ratingValue, setRatingValue] = useState(0);
  const [ratingInfo, setRatingInfo] = useState(null);

  useEffect(() => {
    const userIdName = meetingInfo.user1.id === user.uid ? "user1" : "user2";
    const ratingId = meetingInfo[userIdName].rating_id;
    if (ratingId != null && ratingId !== "") {
      firestore()
        .collection("ratings")
        .doc(ratingId)
        .get()
        .then(ratingDoc => {
          if (ratingDoc.exists) {
            setRatingInfo(ratingDoc.data());
          }
        });
    }
  }, [actions, meetingInfo, user]);
  const onSubmit = data => {
    const {
      inappropriate_partner_behavior,
      rematch_interest,
      technical_difficulties,
      general_feedback
    } = data;

    const conversation_rating = ratingValue;
    const userIdName = meetingInfo.user1.id === user.uid ? "user1" : "user2";
    const ratingId = meetingInfo[userIdName].rating_id;

    if (ratingId != null && ratingId !== "") {
      firestore()
        .collection("ratings")
        .doc(ratingId)
        .update({
          inappropriate_partner_behavior,
          rematch_interest,
          technical_difficulties,
          general_feedback,
          conversation_rating,
          happened: true
        })
        .then(docRef => {
          setActions(actions + 1);
        });
    }
  };

  if (ratingInfo == null || ratingInfo?.happened === true) {
    return null;
  }
  return (
    <div className="matchRatingContainer">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__div">
          <label htmlFor="rematch_interest">
            How would you rate your meal with your FoodFriend
          </label>
          <Rating
            name="rating"
            precision={0.5}
            value={ratingValue}
            onChange={(e, value) => {
              setRatingValue(value);
            }}
            size="large"
          />
        </div>

        <div className="form__div">
          <label htmlFor="rematch_interest">
            Would you like another meeting? (with a new person)
          </label>
          <div>
            <label htmlFor="yes">Yes</label>
            <input
              ref={register({ required: true })}
              type="radio"
              id="rematch_interest"
              name="rematch_interest"
              value="yes"
              required
              defaultChecked
            />
          </div>
          <div>
            <label htmlFor="no">No</label>
            <input
              ref={register}
              type="radio"
              id="rematch_interest"
              name="rematch_interest"
              value="no"
            />
          </div>
        </div>

        <div className="form__div">
          <label htmlFor="technical_difficulties">
            Did you experience any techincal difficulties?
          </label>
          <div>
            <label htmlFor="yes">Yes</label>
            <input
              ref={register}
              type="radio"
              id="technical_difficulties"
              name="technical_difficulties"
              value="yes"
              required
            />
          </div>
          <div>
            <label htmlFor="no">No</label>
            <input
              ref={register}
              type="radio"
              id="technical_difficulties"
              name="technical_difficulties"
              value="no"
            />
          </div>
        </div>

        <div className="form__div">
          <label htmlFor="inappropriate_partner_behavior">
            Was there any inappropriate behavior by your partner?
          </label>
          <div>
            <label htmlFor="yes">Yes</label>
            <input
              ref={register}
              type="radio"
              id="inappropriate_partner_behavior"
              name="inappropriate_partner_behavior"
              value="yes"
              required
            />
          </div>
          <div>
            <label htmlFor="no">No</label>
            <input
              ref={register}
              type="radio"
              id="inappropriate_partner_behavior"
              name="inappropriate_partner_behavior"
              value="no"
            />
          </div>
        </div>

        <div className="form__div">
          <label htmlFor="general_feedback">Any other feedback?</label>
          <textarea
            ref={register}
            name="general_feedback"
            id="general_feedback"
          ></textarea>
        </div>

        <div className="form__div">
          <input type="submit" />
        </div>
      </form>
    </div>
  );
}
